<template>
  <v-row>
    <v-col cols="12">
      <v-btn
        color="primary"
        small
        depressed
        :disabled="showForm == 1"
        @click="create"
      >
        <svg-icon icon="add" outlined class="mr-2"></svg-icon>
        {{ $t("new.offer") }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-carousel
        vertical
        hide-delimiters
        light
        :show-arrows="false"
        height="auto"
        v-model="showForm"
      >
        <v-carousel-item>
          <data-table
            :headers="headers"
            :items="offers"
            :options.sync="pagination"
            :btn-action-path="actionPath"
            :total="offers.length"
          >
            <template #[`item.price`]="{ item }">
              {{ item.price || item.price_value }}
            </template>

            <template #[`item.active`]="{ item }">
              <active-icon :active="item.active" />
            </template>

            <template #[`item.actions`]="{ item }">
              <v-btn @click="editItem(item)" icon text color="primary" small>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                @click="deleteItem(item)"
                icon
                text
                color="red lighten-2"
                small
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </data-table>
        </v-carousel-item>
        <v-carousel-item>
          <v-sheet
            color="grey lighten-3"
            class="tw-shadow-md pa-4"
            v-if="showForm == 1"
          >
            <form-observer
              @save="save"
              @cancel="cancel"
              hide-top-actions
              :hide-cancel-action="!offers.length"
            >
              <offer-field v-model="offer" />
            </form-observer>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { DataTableHeader, DataOptions } from "vuetify";
import FormObserver from "@bit/planetadeleste.gui.form.form-observer/Observer.vue";
import SheetDrawer from "@/layout/components/main/SheetDrawer.vue";
import OfferField from "@/components/form/fields/Offer.vue";
import { Route } from "vue-router";
import { Offer as OfferModel } from "@planetadeleste/vue-mc-shopaholic";
import ActiveIcon from "@/components/common/ActiveIcon.vue";

@Component({
  components: {
    SheetDrawer,
    FormObserver,
    OfferField,
    ActiveIcon,
  },
})
export default class FormOfferFields extends Vue {
  showForm = 0;
  itemIndex = -1;
  routeFrom: Route | null = null;
  offer: OfferModel | null = null;
  @VModel({ type: Array, default: () => [] }) offers!: OfferModel[];

  headers: DataTableHeader[] = [
    { text: "name", value: "name" },
    { text: "code", value: "code", class: "mw-150" },
    { text: "active", value: "active" },
    {
      text: "price",
      value: "price",
      sortable: false,
      class: "mw-150",
    },
    { text: "quantity", value: "quantity" },
    {
      text: "actions",
      value: "actions",
      align: "end",
      sortable: false,
      class: "mw-150",
    },
  ];

  pagination: DataOptions = {
    page: 1,
    itemsPerPage: 20,
    sortBy: [],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [false],
    multiSort: false,
    mustSort: false,
  };

  save() {
    if (!this.offer) {
      return;
    }

    const obOffer = this.$_.clone(this.offer);

    if (!this.offers.length) {
      this.offers.push(obOffer);
    } else {
      const iIndex =
        this.itemIndex >= 0
          ? this.itemIndex
          : this.$_.findIndex(this.offers, { id: obOffer.id });

      if (iIndex >= 0) {
        this.$set(this.offers, iIndex, obOffer);
      } else {
        this.offers.push(obOffer);
      }
    }

    this.$emit("input", this.offers);
    this.cancel();
  }

  cancel() {
    this.offer = new OfferModel();
    this.itemIndex = -1;
    this.showForm = 0;
  }

  create() {
    this.offer = new OfferModel();
    this.itemIndex = -1;
    this.showForm = 1;
  }

  editItem(item: OfferModel) {
    this.itemIndex = this.$_.findIndex(this.offers, item);
    this.offer = this.$_.clone(item);
    this.showForm = 1;
  }

  async deleteItem(item: OfferModel) {
    const sMessage = this.$_.toString(this.$t("ask.remove.offer"));
    const bRes = await this.$confirm(sMessage, { color: "warning" });

    if (bRes) {
      this.itemIndex = this.$_.findIndex(this.offers, item);
      this.offers.splice(this.itemIndex, 1);

      this.$emit("input", this.offers);
      if (!this.offers.length) {
        this.create();
      }
    }
  }

  mounted() {
    if (!this.offers.length) {
      this.create();
    }
  }

  get actionPath() {
    const path = this.routeFrom ? this.routeFrom.path : this.$route.path;
    return `${path}/offer`;
  }
}
</script>
